// File: LineConfig.js

import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {fetchadjustDailyPortfolioData} from "./fetchDailyPortfolioData";
import {
    getLineGradient,
    getLineBorderGradient,
    getLineGradient2,
    getLineBorderGradient2,
} from "./GradientUtils";

// For filtering by date
import {subMonths, subYears} from "date-fns";

const isBrowser = typeof window !== "undefined";
const isMobile = isBrowser && window.innerWidth < 768;

const sizeResponsive = {
    padding: {
        top: isMobile ? 12 : 21,
        right: isMobile ? 12 : 41,
        bottom: isMobile ? 14 : 19,
        left: isMobile ? 15 : 21,
    },
    titleFont: {
        size: isMobile ? 8 : 12,
    },
    bodyFont: {
        size: isMobile ? 12 : 16,
        lineHeight: isMobile ? 1 : 1.5,
    },
    caretSize: isMobile ? 8 : 10,
};

export const verticalLinePlugin = {
    id: "verticalLinePlugin",
    afterDatasetsDraw: function (chart) {
        const {
            ctx,
            chartArea: {top, bottom},
            scales: {x},
        } = chart;
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0];
            const xCoord = activePoint.element.x;

            ctx.save();
            ctx.beginPath();
            ctx.setLineDash([]);
            ctx.moveTo(xCoord, top);
            ctx.lineTo(xCoord, bottom);
            ctx.lineWidth = 0.89;
            ctx.strokeStyle = "rgba(255, 255, 255, 0.50)";
            ctx.stroke();
            ctx.restore();
        }
    },
};

/**
 * 1) Add a 'timeRange' parameter to control how we filter the data (1M, 3M, 1Y, ALL)
 */
export const useLineData = (timeRange = "ALL") => {
    const location = useLocation();

    const [lineData, setLineData] = useState({
        labels: [],
        datasets: [
            {
                label: "Total MEV Distributed through OMAX1 (in ETH)",
                data: [],
                fill: true,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const {chartArea} = chart;
                    if (!chartArea) {
                        return "null";
                    }
                    return getLineGradient2(chart);

                },
                borderColor: (context) => {
                    const chart = context.chart;
                    const {chartArea} = chart;
                    if (!chartArea) {
                        return null;
                    }
                    return getLineBorderGradient2(chart);

                },
                borderWidth: 1.5,
                tension: 0.5,
            },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchadjustDailyPortfolioData();
                // Convert raw 'labels' into JS Date objects for filtering:
                const dateObjects = data.labels.map((label) => new Date(label));
                const allValues = data.datasets4;

                // Decide how far back to filter:
                const now = new Date();
                let minDate = new Date(0); // earliest date for "ALL"
                if (timeRange === "1M") {
                    minDate = subMonths(now, 1);
                } else if (timeRange === "3M") {
                    minDate = subMonths(now, 3);
                } else if (timeRange === "1Y") {
                    minDate = subYears(now, 1);
                }

                const filteredLabels = [];
                const filteredValues = [];

                dateObjects.forEach((dateObj, i) => {
                    if (dateObj >= minDate) {
                        // Convert each date back to "YYYY-MM-DD" (like original code)
                        filteredLabels.push(dateObj.toISOString().split("T")[0]);
                        filteredValues.push(allValues[i]);
                    }
                });

                // Now update lineData
                setLineData({
                    labels: filteredLabels,
                    datasets: [
                        {
                            label: "Total MEV Distributed through OMAX1 (in ETH)",
                            data: filteredValues,
                            fill: true,
                            backgroundColor: (context) => {
                                const chart = context.chart;
                                const {chartArea} = chart;
                                if (!chartArea) {
                                    return "null";
                                }
                                return getLineGradient2(chart);

                            },
                            borderColor: (context) => {
                                const chart = context.chart;
                                const {chartArea} = chart;
                                if (!chartArea) {
                                    return null;
                                }
                                return getLineBorderGradient2(chart);

                            },
                            borderWidth: 1.5,
                            tension: 0.5,
                        },
                    ],
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [timeRange, location.pathname]);

    return lineData;
};

export const getLineOptions = (width, timeRange = "ALL") => {
    const isMobile = width < 1150;

    // Decide if X-axis unit is "day" or "month"
    const xAxisUnit = timeRange === "1M" || timeRange === "3M" ? "day" : "month";
    const dateFormatForTicks =
        xAxisUnit === "day"
            ? {month: "short", day: "numeric"} // e.g. "Dec 8"
            : {month: "short", year: "numeric"}; // e.g. "Dec 2024"
    const options = {
        animation: {
            duration: 1100,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                caretSize: sizeResponsive.caretSize,
                titleAlign: "left",
                bodyAlign: "left",
                padding: {
                    top: sizeResponsive.padding.top,
                    right: sizeResponsive.padding.right,
                    bottom: sizeResponsive.padding.bottom,
                    left: sizeResponsive.padding.left,
                },
                titleFont: {
                    // style: "14px",
                    weight: "normal",
                    size: sizeResponsive.titleFont.size,
                },
                bodyFont: {
                    // style: "18px",
                    weight: "normal",
                    size: sizeResponsive.bodyFont.size,
                },
                intersect: false,
                mode: "index",
                position: "nearest",
                titleColor: "#CFD3D2",
                bodyColor: "#ffffff",
                backgroundColor: "rgba(16, 20, 20, 0.85)",
                borderColor: "rgba(69, 79, 102, 1)",
                borderWidth: 1,
                displayColors: false,
                callbacks: {
                    title: function (tooltipItems) {
                        return tooltipItems[0].label;
                    },
                    label: function (tooltipItem) {
                        const dataset =
                            tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
                        const value = dataset.data[tooltipItem.dataIndex];
                        if (value !== undefined) {
                            const roundedValue = Number(value).toFixed(4);
                            const originalValueFormatted = `$${Number(roundedValue).toLocaleString()}`;
                            const percentageValue = ((value - 1) / 1) * 100;
                            const sign = percentageValue >= 0 ? "+" : "";
                            const percentageFormatted = `${sign}${percentageValue.toFixed(
                                2
                            )}%`;
                            return [originalValueFormatted, percentageFormatted];
                        }
                        return "";
                    },
                },
            },
        },
        elements: {
            point: {
                radius: 0,
            },
            line: {
                tension: 0.5, // keep original
            },
        },
        scales: {
            x: {
                type: "time",
                time: {
                    unit: xAxisUnit,                // "day" or "month"
                    tooltipFormat: "MMMM dd, yyyy", // e.g. "December 08, 2024"
                },
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: true,
                    minTicksLimit: 4,
                    maxTicksLimit: 4,
                    color: "rgba(255, 255, 255, 0.70)",
                    font: {
                        style: "normal",
                        size: 14,
                    },
                    // CHANGE: Now we use dateFormatForTicks
                    callback: function (value) {
                        const date = new Date(value);
                        const formattedValue = date.toLocaleDateString(
                            "en-US",
                            dateFormatForTicks
                        );
                        return isMobile ? formattedValue.split(" ") : formattedValue;
                    },
                    padding: 10,
                },
            },
            y: {
                border: {
                    display: false,
                },
                grid: {
                    color: "rgba(255, 255, 255, 0.2)",
                    lineWidth: 0.5,
                },
                ticks: {
                    color: "rgba(255, 255, 255, 0.70)",
                    font: {
                        size: 14,
                    },
                    callback: function (value) {
                        // 1) If the value is large (>= 1e6), show 'M'
                        if (value >= 1e6) {
                            return (value / 1e6).toFixed(2) + "M";
                        }
                        // 2) If the value is >= 1e3, show 'K'
                        else if (value >= 1e3) {
                            return (value / 1e3).toFixed(2) + "K";
                        }
                        // 3) Otherwise, always show 2 decimals
                        else {
                            return value.toFixed(2);
                        }
                    },
                    padding: 10,
                },
            },

        },
    };

    return options;
};