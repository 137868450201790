import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {getAccessToken} from '../authHelper';
import "./USDIStats.css"
import bs58 from 'bs58';

function UsersTable() {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalStep, setModalStep] = useState('form'); // 'form' or 'confirm'
    const [selectedUserEmail, setSelectedUserEmail] = useState('');
    const [selectedAddressType, setSelectedAddressType] = useState('');
    const [inputAddress, setInputAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Reusable function to fetch users data from check_tables
    const fetchUsers = async () => {
        try {
            const access_token = await getAccessToken();
            const response = await axios.get(
                'https://max1-funding-arb.uc.r.appspot.com/check_tables',
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );

            if (response.data && response.data.users) {
                setUsers(response.data.users);
                console.log("response.data.users", response.data.users)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    // Opens the modal for editing a particular user's address type
    const handleAddressClick = (email, addressType) => {
        setSelectedUserEmail(email);
        setSelectedAddressType(addressType);
        setInputAddress('');
        setModalStep('form');
        setErrorMessage('');
        setShowModal(true);
    };

    // Validation function based on address type
    const validateAddress = (type, address) => {
        const addr = address.trim();
        if (!addr) return "Address cannot be empty.";

        switch (type) {
            case 'ethereum_receive_address':
            case 'bsc_receive_address':
                // Example rule: must start with '0x' and be 42 chars long
                if (!addr.startsWith('0x') || addr.length !== 42) {
                    return "Invalid address format. Must start with '0x' and be 42 characters long.";
                }
                break;
            case 'solana_receive_address': {
                const base58Regex = /^[1-9A-HJ-NP-Za-km-z]+$/;
                // Quick sanity check on length (common Solana addresses are ~43-45 chars)
                if (!base58Regex.test(addr) || addr.length < 32 || addr.length > 50) {
                    return "Invalid Solana address (incorrect characters or length).";
                }

                try {
                    const decoded = bs58.decode(addr);
                    if (decoded.length !== 32) {
                        return "Invalid Solana address. Decoded address must be 32 bytes.";
                    }
                } catch (e) {
                    return "Invalid Solana address. Decoding from Base58 failed.";
                }
            }
                break;
            case 'tron_receive_address':
                // Basic check: Tron address usually starts with 'T'
                if (!addr.startsWith('T')) {
                    return "Invalid TRON address. Must start with 'T'.";
                }
                break;
            case 'wire_info':
                // For wire_info, let's just require a non-empty input
                if (!addr) {
                    return "Wire info cannot be empty.";
                }
                break;
            default:
                return "Unknown address type.";
        }

        return ""; // no error
    };

    // After user enters new address and clicks "Add wallet"
    const handleAddWalletClick = () => {
        const error = validateAddress(selectedAddressType, inputAddress);
        if (error) {
            setErrorMessage(error);
            return;
        }

        // Move to confirmation step if no error
        setErrorMessage('');
        setModalStep('confirm');
    };

    // Send the POST request to add the single chosen wallet address
    const handleConfirmAddWallet = async () => {
        try {
            const access_token = await getAccessToken();
            const requestData = {
                email: selectedUserEmail,
                [selectedAddressType]: inputAddress
            };

            await axios.post(
                'https://max1-funding-arb.uc.r.appspot.com/add_wallet',
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );

            // Close modal on success and refetch the updated users table
            setShowModal(false);
            fetchUsers(); // Refetch the table data after successful update
        } catch (error) {
            console.error('Error adding wallet:', error);
            // Handle error (maybe setErrorMessage with a user-friendly message)
            setErrorMessage('Failed to add wallet. Please try again later.');
        }
    };

    // Determine address fields for the clickable cells
    const addressFields = [
        'ethereum_receive_address',
        'bsc_receive_address',
        'tron_receive_address',
        'solana_receive_address',
        'wire_info'
    ];

    return (
        <div>
            {/* Second table */}
            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th>Email</th>
                        <th>Ethereum Receive Address</th>
                        <th>BSC Receive Address</th>
                        <th>Tron Receive Address</th>
                        <th>Solana Receive Address</th>
                        <th>Wire Info</th>
                        <th>Create Wallet Request</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) => (
                        <tr key={index}>
                            <td>{user.email || ''}</td>
                            {addressFields.map((field) => (
                                <td
                                    key={field}
                                    className="clickable-cell"
                                    onClick={() => handleAddressClick(user.email, field)}
                                    title="Click to add/change address to this wallet"
                                    style={{cursor: 'pointer'}}
                                >
                                    {user[field] || ''}
                                </td>
                            ))}
                            <td>
                                {user.create_wallet_requested ? 'Yes' : 'No'}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="modal d-block" tabIndex="-1" role="dialog" style={{background: 'rgba(0,0,0,0.5)'}}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {modalStep === 'form' && (
                                <>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Add Address for {selectedUserEmail}</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>Address Type: {selectedAddressType}</p>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter new address"
                                            value={inputAddress}
                                            onChange={(e) => setInputAddress(e.target.value)}
                                        />
                                        {errorMessage && (
                                            <div className="alert alert-danger mt-2">{errorMessage}</div>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={() => setShowModal(false)}>
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary"
                                                onClick={handleAddWalletClick}>
                                            Add wallet
                                        </button>
                                    </div>
                                </>
                            )}

                            {modalStep === 'confirm' && (
                                <>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Confirm Wallet Addition</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>Email: {selectedUserEmail}</p>
                                        <p>Type: {selectedAddressType}</p>
                                        <p>New Address: {inputAddress}</p>
                                        <p>Please confirm to add this wallet address.</p>
                                        {errorMessage && (
                                            <div className="alert alert-danger mt-2">{errorMessage}</div>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={() => setShowModal(false)}>
                                            Cancel
                                        </button>
                                        <button type="button" className="btn btn-success"
                                                onClick={handleConfirmAddWallet}>
                                            Confirm
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UsersTable;
