import React, {useState, useEffect, useRef, useContext} from 'react';
import styles from './SwapMain.module.css';
import LineChart from "./linechart/LineCharts";
import usdiImg from "./static_files/USDi.png";
import usdtImg from "./static_files/usdt 1.svg";
import usdcImg from "./static_files/usdc.svg";
import usdImg from "./static_files/usd.svg";
import approxImg from "./static_files/Text.svg";
import {fetchPortfolioTrackRecordsRoundFourDigit} from "./linechart/fetchDailyPortfolioData";
import TransactionDetailModal from "./transaction_detail/TransactionDetail";
/* Normal copy icon & hover version */
import copySelectorImg from "./static_files/copy-selector.svg";
import copySelectorHoverImg from "./static_files/copy-selector-hover.svg";
import copyConfirmImg from "./static_files/checkmark.svg";

import shareSelectorImg from "./static_files/share-selector.svg";
import shareSelectorHoverImg from "./static_files/share-selector-hover.svg";

import dropDownRightImg from "./static_files/dropdownright.svg"
import dropDownRightHoverImg from "./static_files/dropdownrightHover.svg"

import dropDownImg from "./static_files/dropdown.svg"
import dropDownHoverImg from "./static_files/dropdownHover.svg"

import ConnectWalletHeader from "./header/ConnectWalletHeader";
import LeftPanel from "./SwapLeftPanel/LeftPanel";
import {SwapMainAppContext} from "../SwapMainAppContext";

import {motion, LayoutGroup} from 'framer-motion';
import {shortenAddress} from './utils'

import {useAuth} from "../AuthContext";


const SwapMainAPP = () => {
    const {gatewayUserData} = useAuth();
    const [usdiValue, setUsdiValue] = useState(null);
    const [usdiHoldersData, setUsdiHoldersData] = useState();
    const [availableUSDI, setAvailableUSDI] = useState(null);
    const [cumulativeInterest, SetCumulativeInterest] = useState(0);
    const [selectedTab, setSelectedTab] = useState('mint'); // "mint" | "redeem"
    const [ImpliedApy, setImpliedApr] = useState([]);
    const [currentUserData, setCurrentUserData] = useState(null);
    const [txTrigger, setTxTrigger] = useState(0);

    // Wallet data 连接钱包后的pub key在这
    const [walletAddress, setWalletAddress] = useState(null);
    const [usdtBalance, setUsdtBalance] = useState(0);
    const [usdcBalance, setUsdcBalance] = useState(0);
    const [usdiBalance, setUsdiBalance] = useState(0);
    // use to open connect wallet side panel
    const [showPanel, setShowPanel] = useState(false);

    // Which token is selected ("USDC" or "USDT" or "CASH" from redeem)
    const [selectedToken, setSelectedToken] = useState("USDC");

    // From your API
    const [squadsPublicKey, setSquadsPublicKey] = useState("");
    const [usdcTokenAddress, setUsdcTokenAddress] = useState("");
    const [usdtTokenAddress, setUsdtTokenAddress] = useState("");

    // Show/hide token selector modal
    const [showTokenSelector, setShowTokenSelector] = useState(false);
    const methodItemRef = useRef(null);
    const tokenSelectorRef = useRef(null); // for clicking somewhere else to close it

    // Single input for both Mint and Redeem tabs
    const [typedMintValue, setTypedMintValue] = useState("0");

    // Final “confirmed” amount to pass to ConnectWalletHeader
    const [confirmedAmount, setConfirmedAmount] = useState("0");
    const [justCopiedUSDC, setJustCopiedUSDC] = useState(false);
    const [justCopiedUSDT, setJustCopiedUSDT] = useState(false);


    // Font-size + container ref + hidden measuring span + input ref
    const [dynamicFontSize, setDynamicFontSize] = useState(100);
    const containerRef = useRef(null);  // to get container width
    const spanRef = useRef(null);       // hidden measuring <span>
    const inputRef = useRef(null);      // actual input

    // Min/Max for font
    const MIN_FONT_SIZE = 30;
    const MAX_FONT_SIZE = 100;

    const {showNotification, networkFeePercent} = useContext(SwapMainAppContext);


    // for transaction detail page - > after cash redeem , show modal let user confirm
    const [showTransactionDetailModal, setShowTransactionDetailModal] = useState(false);
    const [redeemValueForModal, setRedeemValueForModal] = useState(0);
    const [localTimeTransaction, setLocalTimeTransaction] = useState(null);
    const [UTCTimeTransaction, setUTCTimeTransaction] = useState(null);


    // ------------------------ FETCH DATA  ------------------------
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Promise.any([
                    fetchPortfolioTrackRecordsRoundFourDigit().catch(e => null),
                ]);
                if (data) {
                    const maxApy = Math.max(data.impliedApr, data.ninetyDayApy, data.thirtyDayApy);
                    setUsdiValue(data.totalValue / data.usdiInCirculation);
                    setImpliedApr(maxApy);
                } else {
                    console.error("Failed to fetch one or more data endpoints");
                }

                const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/usdi_holders_info_table_solana');
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();
                setUsdiHoldersData(result);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchSquadsKey = async () => {
            try {
                const res = await fetch("https://usdi-api-256611876551.europe-west1.run.app/usdi_squads_public_key");
                if (!res.ok) {
                    throw new Error("Failed to fetch squads public key");
                }
                const data = await res.json();
                if (data && data.USDi_Squads_Public_Key) {
                    setSquadsPublicKey(data.USDi_Squads_Public_Key);
                }

                const res1 = await fetch("https://usdi-api-256611876551.europe-west1.run.app/verified_token_solana_usdc");
                if (!res1.ok) {
                    throw new Error("Failed to fetch verified_token_solana_usdc");
                }
                const data1 = await res1.json();
                if (data1 && data1.verified_token_solana_usdc) {
                    setUsdcTokenAddress(data1.verified_token_solana_usdc);
                }

                const res2 = await fetch("https://usdi-api-256611876551.europe-west1.run.app/verified_token_solana_usdt");
                if (!res2.ok) {
                    throw new Error("Failed to fetch verified_token_solana_usdt");
                }
                const data2 = await res2.json();
                if (data2 && data2.verified_token_solana_usdt) {
                    setUsdtTokenAddress(data2.verified_token_solana_usdt);
                }
            } catch (error) {
                console.error("Error fetching squads public key:", error);
            }
        };

        fetchSquadsKey();
    }, []);

    // If we have user data from DB + holders, sum up USDi
    useEffect(() => {
        if (usdiHoldersData && currentUserData) {
            const userAddresses = [
                currentUserData.bsc_receive_address,
                currentUserData.ethereum_receive_address,
                currentUserData.tron_receive_address,
                currentUserData.solana_receive_address
            ].filter(Boolean);

            const uniqueAddresses = [...new Set(userAddresses.map(a => a.toLowerCase()))];

            let totalBalance = 0;
            let totalDistributedUsdi = 0;
            for (const holder of usdiHoldersData) {
                const holderAddress = holder.address.toLowerCase();
                if (uniqueAddresses.includes(holderAddress)) {
                    const balance = parseFloat(holder.balance_of_usdi) || 0;
                    totalBalance += balance;
                    const DistributedUsdi = parseFloat(holder.cum_distributed_usdi) || 0;
                    totalDistributedUsdi += DistributedUsdi;
                }
            }

            setAvailableUSDI(totalBalance);
            SetCumulativeInterest(totalDistributedUsdi);
        }
    }, [usdiHoldersData, currentUserData]);
    useEffect(() => {
        setCurrentUserData((prevData) => ({
            ...prevData,
            solana_receive_address: walletAddress,
        }));

    }, [walletAddress])
    useEffect(() => {
        setCurrentUserData(gatewayUserData)
        console.log("set user data here", gatewayUserData)
    }, [gatewayUserData]);
    useEffect(() => {
        function handleClickOutside(e) {
            if (!showTokenSelector) return;  // If dropdown not open, do nothing
            if (
                methodItemRef.current?.contains(e.target) ||
                tokenSelectorRef.current?.contains(e.target)
            ) {
                return;
            }
            setShowTokenSelector(false);
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTokenSelector]);
    // ------------------------ MINT & REDEEM ------------------------
    const handleMintUSDi = () => {
        const mintValueAsNumber = parseFloat(typedMintValue);
        if (isNaN(mintValueAsNumber) || mintValueAsNumber <= 0) {
            showNotification('error', "Please enter a valid amount to mint.");

            return;
        }

        setConfirmedAmount(mintValueAsNumber);
        setTxTrigger((prev) => prev + 1);
        // setTypedMintValue("0");
    };

    const handleRedeemUSDi = () => {
        const redeemValueAsNumber = parseFloat(typedMintValue);
        if (isNaN(redeemValueAsNumber) || redeemValueAsNumber <= 0) {
            showNotification('error', "Please enter a valid amount to mint.");
            return;
        }
        // todo change it back once compleete < 100
        // if (selectedToken === "CASH" && confirmedAmount < 100) {
        //     showNotification('warning', `Minimum for cash redemption: 100 USDi`);
        //     return
        // }
        if (selectedToken === "CASH") {
            getLocalAndUTCTime();
            setRedeemValueForModal(redeemValueAsNumber);
            setShowTransactionDetailModal(true);
            return;
        }
        setConfirmedAmount(redeemValueAsNumber);
        setTxTrigger((prev) => prev + 1);
    };

    // ------------------------ Show/hide token selector ------------------------
    const handleMethodItemClick = (e) => {
        e.stopPropagation();
        setShowTokenSelector(prev => !prev);
    };

    // ------------------------ COPY FUNCTION ------------------------
    const handleCopy = (e, address, token) => {
        e.stopPropagation();
        navigator.clipboard.writeText(address).then(() => {
            console.log("Copied address:", address);

            if (token === "USDC") {
                setJustCopiedUSDC(true);
                setTimeout(() => setJustCopiedUSDC(false), 2000);
            } else if (token === "USDT") {
                setJustCopiedUSDT(true);
                setTimeout(() => setJustCopiedUSDT(false), 2000);
            }
        });
    };

    // ------------------------ SHARE FUNCTION ------------------------
    const handleShare = (e, address) => {
        e.stopPropagation();
        window.open(`https://solscan.io/token/${address}`, "_blank");
    };

    // ------------------------ SELECT TOKEN LOGIC ------------------------
    const handleSelectToken = (token) => {
        if (!justCopiedUSDC && !justCopiedUSDT) {
            setSelectedToken(token);
            setShowTokenSelector(false);
        }
    };

    // ------------------------ “Max” button & validation ------------------------
    const handleSetMax = () => {
        if (selectedTab === 'mint') {
            if (selectedToken === 'USDT') {
                setTypedMintValue(String(usdtBalance));
            } else {
                setTypedMintValue(String(usdcBalance));
            }
        } else if (selectedTab === 'redeem') {
            setTypedMintValue(String(usdiBalance));
        }
    };

    // ------------------------ Handle input changes (decimal limit) ------------------------
    const handleInputChange = (e) => {
        let val = e.target.value;
        val = val.replace(/[^\d.]/g, '');
        if (val === '.0') {
            val = '0.'; // specifically handle ".0"
        } else if (val.startsWith('.') && val !== '.') {
            val = '0' + val;
        }
        const firstDotIndex = val.indexOf('.');
        if (firstDotIndex !== -1) {
            val =
                val.slice(0, firstDotIndex + 1) +
                val.slice(firstDotIndex + 1).replace(/\./g, '');
        }
        const dotIndex = val.indexOf('.');
        if (dotIndex !== -1) {
            const fractional = val.slice(dotIndex + 1, dotIndex + 1 + 6);
            val = val.slice(0, dotIndex + 1) + fractional;
        }

        if (val === '.') {
            setTypedMintValue('0.');
            return;
        }

        if (val.length > 1 && val[0] === '0' && val[1] !== '.') {
            val = val.replace(/^0+/, '');
            if (val === '') {
                val = '0';
            }
        }

        if (!val.endsWith('.')) {
            const numericVal = parseFloat(val);
            if (!isNaN(numericVal)) {
                let maxVal = 0;
                if (selectedTab === 'mint') {
                    maxVal = selectedToken === 'USDT' ? usdtBalance : usdcBalance;
                } else if (selectedTab === 'redeem') {
                    maxVal = usdiBalance;
                }

                if (numericVal > maxVal) {
                    val = maxVal.toFixed(6).replace(/\.?0+$/, '');
                }
            } else {
                val = '0';
            }
        }
        setTypedMintValue(val);
    };
    // for pass in to connect wallet header to open connect wallet side panel
    const handleConnectWallet = () => {
        setShowPanel(true);
    };

    /// For transaction Time
    function getLocalAndUTCTime() {
        const now = new Date();  // Current moment in time
        // --- Local Time ---
        const localYear = now.getFullYear();
        const localMonth = String(now.getMonth() + 1).padStart(2, '0');
        const localDay = String(now.getDate()).padStart(2, '0');

        const localHours = String(now.getHours()).padStart(2, '0');
        const localMinutes = String(now.getMinutes()).padStart(2, '0');
        const localSeconds = String(now.getSeconds()).padStart(2, '0');
        const localTime = `${localMonth}/${localDay}/${localYear}, ${localHours}:${localMinutes}:${localSeconds}`;

        // --- UTC Time (same moment) ---
        const utcYear = now.getUTCFullYear();
        const utcMonth = String(now.getUTCMonth() + 1).padStart(2, '0');
        const utcDay = String(now.getUTCDate()).padStart(2, '0');

        const utcHours = String(now.getUTCHours()).padStart(2, '0');
        const utcMinutes = String(now.getUTCMinutes()).padStart(2, '0');
        const utcSeconds = String(now.getUTCSeconds()).padStart(2, '0');
        const utcTime = `${utcMonth}/${utcDay}/${utcYear}, ${utcHours}:${utcMinutes}:${utcSeconds}`;

        // Return as an array or object
        setLocalTimeTransaction(localTime)
        setUTCTimeTransaction(utcTime)
        console.log("set utc time here", utcTime, UTCTimeTransaction)
    }


    // ------------------------ Force re-mount with new font size via key ------------------------
    // Called each time AutosizeInput calculates a width
    // ------------------------ UseEffect to measure text & adjust font size & input width ------------------------
    useEffect(() => {
        if (!containerRef.current || !spanRef.current || !inputRef.current) return;

        // 1) Mirror the input's text
        spanRef.current.textContent = typedMintValue === '' ? '0' : typedMintValue;

        // 2) Match the input's exact font styles so measurement is accurate
        //    (Assuming you have fontSize in dynamicFontSize, but also replicate font-family, weight, etc.)
        spanRef.current.style.fontSize = `${dynamicFontSize}px`;
        spanRef.current.style.fontFamily = getComputedStyle(inputRef.current).fontFamily;
        spanRef.current.style.fontWeight = getComputedStyle(inputRef.current).fontWeight;
        spanRef.current.style.lineHeight = getComputedStyle(inputRef.current).lineHeight;
        spanRef.current.style.letterSpacing = getComputedStyle(inputRef.current).letterSpacing;

        // 3) Use getBoundingClientRect() for a more reliable measurement
        const rect = spanRef.current.getBoundingClientRect();
        spanRef.current.textContent = "USDi"
        const rect_usdi = spanRef.current.getBoundingClientRect();
        const usdi_width = rect_usdi.width
        const textWidth = rect.width;
        const containerWidth = containerRef.current.offsetWidth - usdi_width - 30 - 58 - 40;
        // Subtract any side icons/padding.
        // (Don't change your existing logic here, just an example.)

        // 4) If text is too wide, shrink font
        if (textWidth > containerWidth && dynamicFontSize > MIN_FONT_SIZE) {
            const ratio = containerWidth / textWidth;
            let newFont = Math.floor(dynamicFontSize * ratio);
            if (newFont < MIN_FONT_SIZE) newFont = MIN_FONT_SIZE;
            setDynamicFontSize(newFont);
            return;
        }

        // 5) If text is small, optionally grow
        if (textWidth < containerWidth * 0.8 && dynamicFontSize < MAX_FONT_SIZE) {
            const newFont = Math.min(MAX_FONT_SIZE, dynamicFontSize + 2);
            setDynamicFontSize(newFont);
            return;
        }

        // 6) Finally, set the input's width to the measured textWidth + buffer
        inputRef.current.style.width = `${textWidth}px`;
    }, [typedMintValue, dynamicFontSize, selectedTab]);

    // ------------------------ RENDER ------------------------
    return (
        <LayoutGroup>
            <div
                className={styles.outerContainer}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: '#04070D',
                    height: '150%',
                    width: '100vw'
                }}
            >
                {showTransactionDetailModal && (
                    <TransactionDetailModal
                        fromAmount={redeemValueForModal}
                        fromCurrency="USDi"
                        toCurrency="USD Cash"
                        transactionType="Redeem"
                        network="C2C"
                        rate="1 USDi = 1 USD"
                        eta="2 Days"
                        notes="Note: First, pay the network fee to initiate the transaction. Then, pay the remaining amount upon delivery."
                        localTime={localTimeTransaction}
                        // When user confirms, finish the redeem:
                        onConfirm={() => {
                            setConfirmedAmount(redeemValueForModal);
                            setTxTrigger((prev) => prev + 1);
                            setTypedMintValue("0");
                            setShowTransactionDetailModal(false);
                        }}
                        onClose={() => setShowTransactionDetailModal(false)}

                    />
                )}

                {/* LEFT PANEL */}
                <LeftPanel/>

                {/* RIGHT PANEL */}
                <div className={styles.rightPanel} style={{display: "flex", flexDirection: "column"}}>
                    <ConnectWalletHeader
                        amount={confirmedAmount}
                        transactionType={selectedTab}
                        transactionTrigger={txTrigger}
                        toAddress="4dJ5ATt3BbJPrbYCZGLAXndi5DPaZZY9j1Sp8Hdh4ApH"
                        selectedToken={selectedToken}
                        onWalletConnected={(publicKey, usdtLeft, usdcLeft, usdiLeft) => {
                            setWalletAddress(publicKey);
                            setUsdtBalance(usdtLeft);
                            setUsdcBalance(usdcLeft);
                            setUsdiBalance(usdiLeft);
                        }}
                        onSignature={(signature) => {
                            // no-op
                        }}
                        transactionTime={UTCTimeTransaction}
                        showPanel={showPanel}
                        setShowPanel={setShowPanel}
                    />

                    <div className={styles.container}>
                        {/* LEFT CONTAINER */}
                        <div className={styles.leftContainer}>
                            <div className={styles.balanceSection}>
                                <div className={styles.chartWrapper}>
                                    <LineChart
                                        chartBgColor="#1c1c1c"
                                        maxWidth="481px"
                                        availableUSDI={availableUSDI}
                                        usdiValue={usdiValue}
                                    />
                                </div>

                                <div className={styles.usdiInfo}>
                                    <div className={styles.usdiHeader}>
                                        <div className={styles.usdiItemLeft}>
                                            <img className={styles.usdiImage} src={usdiImg} alt="USDi"/>
                                            <p>USDi</p>
                                        </div>
                                        <div className={styles.innerUsdiApy}>
                                            <div className={styles.usdiBox}>
                                                <div
                                                    className={styles.usdiAPYtitle}
                                                    style={{marginBottom: '25px'}}
                                                >
                                                    Balance
                                                </div>
                                                <div className={styles.usdiValue2}>
                                                    {availableUSDI ? availableUSDI.toFixed(2) : '--'}
                                                </div>
                                                <div style={{background: '#1c1c1c'}}>
                                                    {availableUSDI && usdiValue ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'end',
                                                                background: '#1c1c1c'
                                                            }}
                                                        >
                                                            <img
                                                                style={{background: '#1c1c1c'}}
                                                                src={approxImg}
                                                                alt="≈"
                                                            />
                                                            <span className={styles.usdiValue}>
                                                            {`$${(availableUSDI * usdiValue).toFixed(2)}`}
                                                        </span>
                                                        </div>
                                                    ) : (
                                                        '--'
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.usdiItemRight}>
                                                <div
                                                    className={styles.usdiAPYtitle}
                                                    style={{display: "flex", justifyContent: "right"}}
                                                >
                                                    APY
                                                </div>
                                                <h5>
                                                    {ImpliedApy !== undefined && ImpliedApy !== null && !isNaN(ImpliedApy)
                                                        ? `${Number(ImpliedApy).toFixed(2)}%`
                                                        : '--'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.lineSeparator}></div>
                                    <div className={styles.usdiStats}>
                                        <div className={styles.usdiDataWrapper}>
                                            <h4>
                                                {usdiValue
                                                    ? `${parseFloat(usdiValue).toFixed(2)} `
                                                    : '--'
                                                }
                                            </h4>
                                            <p>NAV per USDI</p>
                                        </div>
                                        <div className={`${styles.usdiDataWrapper} ${styles.usdiDataWrapper2}`}>
                                            <h4>
                                                {cumulativeInterest ? (
                                                    <>
                                                        {parseFloat(cumulativeInterest).toFixed(2)}
                                                        <span className={styles.usdiLabel}> USDi</span>
                                                    </>
                                                ) : (
                                                    '--'
                                                )}
                                            </h4>
                                            <p>Cumulative Interest</p>
                                        </div>
                                        <div className={`${styles.usdiDataWrapper} ${styles.usdiDataWrapper3}`}>
                                            <h4>
                                                {ImpliedApy && availableUSDI && usdiValue
                                                    ? `$${(0.01 * ImpliedApy * availableUSDI * usdiValue / 12).toFixed(2)}`
                                                    : '--'
                                                }
                                            </h4>
                                            <p>30-Day Interest</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* RIGHT CONTAINER */}
                        <div className={styles.rightContainer}>

                            <div className={styles.tabs}>
                                <button
                                    className="relative"
                                    onClick={() => {
                                        if (selectedTab === 'redeem') {
                                            setTypedMintValue("0");
                                        }
                                        setSelectedTab('mint');
                                    }}
                                >
                                    {selectedTab === 'mint' && (
                                        // <motion.div layoutId="active-pill" className={styles.tapPill}    />)}
                                        <motion.div layoutId="active-pill" className={styles.tapPill}
                                                    transition={{type: "spring", duration: 0.6}}/>)}
                                    <span
                                        className={`${styles.tabText} ${selectedTab !== 'mint' ? styles.nonSelectTabText : ''}`}
                                    > Mint</span>
                                </button>
                                <button
                                    className='relative'
                                    onClick={() => {
                                        if (selectedTab === 'mint') {
                                            setTypedMintValue("0");
                                        }
                                        setSelectedTab('redeem');
                                    }}
                                >
                                    {selectedTab === 'redeem' && (
                                        // <motion.div layoutId="active-pill" className={styles.tapPill}    />)}
                                        <motion.div layoutId="active-pill" className={styles.tapPill}
                                                    transition={{type: "spring", duration: 0.6}}/>)}
                                    <span
                                        className={`${styles.tabText} ${selectedTab !== 'redeem' ? styles.nonSelectTabText : ''}`}
                                    >
                                      Redeem
                                    </span>
                                </button>

                            </div>


                            {/* -------- MINT TAB -------- */}
                            {selectedTab === 'mint' && (
                                <div className={styles.withdrawSection}>
                                    {/* We measure the container here */}
                                    <div className={styles.withdrawSectionMiddle}>
                                        <div
                                            ref={containerRef}
                                            className={styles.customInputWrapper}
                                            style={{position: 'relative'}}
                                            onClick={() => inputRef.current?.focus()}

                                        >
                                            <input
                                                ref={inputRef}
                                                type="text"
                                                className={styles.withDrawBig}
                                                value={typedMintValue}
                                                onChange={handleInputChange}
                                                style={{
                                                    fontSize: dynamicFontSize,
                                                    // width is set dynamically in useEffect
                                                    border: 'none',
                                                    background: '#1c1c1c',
                                                    caretColor: '#6CE3C4',
                                                }}
                                            />
                                            <span
                                                ref={spanRef}
                                                style={{
                                                    position: 'absolute',
                                                    visibility: 'hidden',
                                                    whiteSpace: 'pre',
                                                    padding: 0,
                                                    margin: 0
                                                }}

                                            />
                                            <span
                                                onChange={handleInputChange}
                                                className={styles.fakePlaceholderFont}
                                                style={{
                                                    width: '12px',
                                                    background: '#1c1c1c',
                                                    color: 'gray',
                                                    fontSize: dynamicFontSize
                                                }}
                                                onClick={() => inputRef.current?.focus()}


                                            >
                                            {' '}USDi
                                        </span>

                                            {/* MAX BUTTON */}
                                            <div>
                                                <button
                                                    className={styles.maxButton}
                                                    onClick={handleSetMax}
                                                >
                                                    Max
                                                </button>
                                            </div>
                                        </div>

                                        <div className={styles.withDrawAmount}>
                                            {selectedToken === "USDT"
                                                ? usdtBalance
                                                    ? `${usdtBalance.toLocaleString(undefined, {
                                                        maximumFractionDigits: 6
                                                    })} USDT Available`
                                                    : "0.00 USDT Available"
                                                : usdcBalance
                                                    ? `${usdcBalance.toLocaleString(undefined, {
                                                        maximumFractionDigits: 6
                                                    })} USDC Available`
                                                    : "0.00 USDC Available"
                                            }
                                        </div>

                                        <div
                                            className={styles.methodItem}
                                            onClick={handleMethodItemClick}
                                            ref={methodItemRef}
                                        >
                                            <div
                                                onClick={handleMethodItemClick}
                                                ref={methodItemRef}
                                                className={styles.withDrawItemLeft}
                                            >
                                                {selectedToken === "USDT" ? (
                                                    <img
                                                        className={styles.withdrawImg}
                                                        src={usdtImg}
                                                        alt="wireTransferImg"
                                                        onClick={handleMethodItemClick}
                                                        ref={methodItemRef}
                                                    />
                                                ) : (
                                                    <img
                                                        className={styles.withdrawImg}
                                                        src={usdcImg}
                                                        alt="wireTransferImg"
                                                        onClick={handleMethodItemClick}
                                                        ref={methodItemRef}
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-around',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                    onClick={handleMethodItemClick}
                                                    ref={methodItemRef}
                                                >
                                                    <h4>Pay with</h4>
                                                    <h5>{shortenAddress(walletAddress)}</h5>
                                                </div>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div
                                                    onClick={handleMethodItemClick}
                                                    ref={methodItemRef}
                                                    className={styles.withDrawItemRight}
                                                >
                                                    <h5 onClick={handleMethodItemClick} ref={methodItemRef}>
                                                        {(parseFloat(typedMintValue) || 0) < 5000 ? 'Instant' : '1 day'}
                                                    </h5>                                                <h5
                                                    onClick={handleMethodItemClick} ref={methodItemRef}>
                                                    <span className={styles.payWithValue}>
                                                        {typedMintValue}
                                                    </span>
                                                    {' '}{selectedToken}
                                                </h5>
                                                </div>
                                                {showTokenSelector ? (
                                                    <img
                                                        src={dropDownImg}
                                                        className={styles.arrowDown}
                                                        alt="arrow"
                                                        onMouseEnter={(e) => (e.currentTarget.src = dropDownHoverImg)}
                                                        onMouseLeave={(e) => (e.currentTarget.src = dropDownImg)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={dropDownRightImg}
                                                        className={styles.arrowDown}
                                                        alt="arrow"
                                                        onMouseEnter={(e) => (e.currentTarget.src = dropDownRightHoverImg)}
                                                        onMouseLeave={(e) => (e.currentTarget.src = dropDownRightImg)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Token Selector Modal (Mint) */}
                                    {showTokenSelector && (
                                        <div ref={tokenSelectorRef} className={styles.tokenSelectorModal}>
                                            <div className={styles.tokenSelectorTitleContainer}>
                                                <div className={styles.tokenSelectorTitle}>Token</div>
                                                <div className={styles.tokenSelectorTitle}>Balance/Address</div>
                                            </div>
                                            <div className={styles.tokenSelectorContentContainer}>
                                                {/* USDC Row */}
                                                <div
                                                    className={
                                                        selectedToken === 'USDC'
                                                            ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                            : styles.tokenSelectorContent
                                                    }
                                                    style={
                                                        selectedToken === 'USDC'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                    onClick={() => handleSelectToken("USDC")}
                                                >
                                                    <div
                                                        className={styles.tokenSelectorContentLeft}
                                                        style={
                                                            selectedToken === 'USDC'
                                                                ? {background: '#2b3336'}
                                                                : {}
                                                        }
                                                    >
                                                        <img
                                                            className={styles.selectorImg}
                                                            src={usdcImg}
                                                            alt="wireTransferImg"
                                                            style={
                                                                selectedToken === 'USDC'
                                                                    ? {background: '#2b3336'}
                                                                    : {}
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    marginBottom: '3px',
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDC
                                                            </h4>
                                                            <h5
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USD Coin
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={styles.tokenSelectorWalletContainer}
                                                        style={
                                                            selectedToken === 'USDC'
                                                                ? {background: '#2b3336'}
                                                                : {}
                                                        }
                                                    >
                                                        {usdcBalance ? (
                                                            <div
                                                                className={styles.tokenSelectorContentRight}
                                                                style={
                                                                    selectedToken === 'USDC'
                                                                        ? {background: '#2b3336'}
                                                                        : {}
                                                                }
                                                            >
                                                                {usdcBalance}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textAlign: 'right',
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                --
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                        <span
                                                            className={styles.tokenSelectorContentRightGrey}
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                        >
                                                            {shortenAddress(usdcTokenAddress)}
                                                        </span>
                                                            {/* Copy icon or check icon */}
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                            {justCopiedUSDC ? (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copyConfirmImg}
                                                                    alt="Copied!"
                                                                    style={{
                                                                        background: selectedToken === 'USDC'
                                                                            ? '#2b3336'
                                                                            : 'transparent',
                                                                        width: '11px',
                                                                        height: '11px'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copySelectorImg}
                                                                    alt="copy img"
                                                                    style={{
                                                                        background: selectedToken === 'USDC'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                                    onMouseEnter={(e) =>
                                                                        (e.currentTarget.src = copySelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(e) =>
                                                                        (e.currentTarget.src = copySelectorImg)
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                            <img
                                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                                src={shareSelectorImg}
                                                                alt="share img"
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleShare(e, usdcTokenAddress)}
                                                                onMouseEnter={(e) =>
                                                                    (e.currentTarget.src = shareSelectorHoverImg)
                                                                }
                                                                onMouseLeave={(e) =>
                                                                    (e.currentTarget.src = shareSelectorImg)
                                                                }
                                                            />
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* USDT Row */}
                                                <div
                                                    className={
                                                        selectedToken === 'USDT'
                                                            ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                            : styles.tokenSelectorContent
                                                    }
                                                    style={
                                                        selectedToken === 'USDT'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                    onClick={() => handleSelectToken("USDT")}
                                                >
                                                    <div
                                                        className={styles.tokenSelectorContentLeft}
                                                        style={
                                                            selectedToken === 'USDT'
                                                                ? {background: '#2b3336'}
                                                                : {}
                                                        }
                                                    >
                                                        <img
                                                            className={styles.selectorImg}
                                                            src={usdtImg}
                                                            alt="wireTransferImg"
                                                            style={
                                                                selectedToken === 'USDT'
                                                                    ? {background: '#2b3336'}
                                                                    : {}
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    marginBottom: '3px',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDT
                                                            </h4>
                                                            <h5
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDT
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={styles.tokenSelectorWalletContainer}
                                                        style={{
                                                            background: selectedToken === 'USDT'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        {usdtBalance ? (
                                                            <div
                                                                className={styles.tokenSelectorContentRight}
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                {usdtBalance}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textAlign: 'right',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                --
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                        <span
                                                            className={styles.tokenSelectorContentRightGrey}
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                                        >
                                                            {shortenAddress(usdtTokenAddress)}
                                                        </span>
                                                            {/* Copy or check icon */}
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                            {justCopiedUSDT ? (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copyConfirmImg}
                                                                    alt="Copied!"
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent',
                                                                        width: '11px',
                                                                        height: '11px'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copySelectorImg}
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    alt="copy img"
                                                                    onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                                                    onMouseEnter={(e) =>
                                                                        (e.currentTarget.src = copySelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(e) =>
                                                                        (e.currentTarget.src = copySelectorImg)
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                            <img
                                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                                src={shareSelectorImg}
                                                                alt="share img"
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleShare(e, usdtTokenAddress)}
                                                                onMouseEnter={(e) =>
                                                                    (e.currentTarget.src = shareSelectorHoverImg)
                                                                }
                                                                onMouseLeave={(e) =>
                                                                    (e.currentTarget.src = shareSelectorImg)
                                                                }
                                                            />
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {walletAddress ? <button onClick={handleMintUSDi}>Mint USDi</button> :
                                        <button onClick={handleConnectWallet}>Connect Wallet</button>}
                                </div>
                            )}

                            {/* -------- REDEEM TAB -------- */}
                            {selectedTab === 'redeem' && (
                                <div className={styles.withdrawSection}>
                                    <div className={styles.withdrawSectionMiddle}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                background: '#1c1c1c',
                                                position: 'relative'
                                            }}
                                        >
                                            <div
                                                ref={containerRef}
                                                className={styles.customInputWrapper}
                                                style={{position: 'relative'}}
                                                onClick={() => inputRef.current?.focus()}

                                            >
                                                <input
                                                    ref={inputRef}
                                                    type="text"
                                                    className={styles.withDrawBig}
                                                    value={typedMintValue}
                                                    onChange={handleInputChange}
                                                    style={{
                                                        fontSize: dynamicFontSize,
                                                        // width is set dynamically in useEffect
                                                        border: 'none',
                                                        background: '#1c1c1c',
                                                        caretColor: '#6CE3C4',
                                                    }}
                                                />
                                                <span
                                                    ref={spanRef}
                                                    style={{
                                                        position: 'absolute',
                                                        visibility: 'hidden',
                                                        whiteSpace: 'pre',
                                                        padding: 0,
                                                        margin: 0
                                                    }}
                                                    onClick={() => inputRef.current?.focus()}

                                                />
                                                <span
                                                    className={styles.fakePlaceholderFont}
                                                    style={{
                                                        width: '12px',
                                                        background: '#1c1c1c',
                                                        color: 'gray',
                                                        fontSize: dynamicFontSize
                                                    }}
                                                    onClick={() => inputRef.current?.focus()}

                                                >
                                            {' '}USDi
                                        </span>

                                                {/* MAX BUTTON */}
                                                <button
                                                    className={styles.maxButton}
                                                    onClick={handleSetMax}
                                                >
                                                    Max
                                                </button>
                                            </div>
                                            {/* MAX BUTTON */}
                                            <button
                                                className={styles.maxButton}
                                                onClick={handleSetMax}
                                            >
                                                Max
                                            </button>
                                        </div>
                                        <div className={styles.withDrawAmount}>
                                            {usdiBalance
                                                ? `${usdiBalance.toLocaleString(undefined, {
                                                    maximumFractionDigits: 6
                                                })} USDi Available`
                                                : '0.00 USDi Available'
                                            }
                                        </div>
                                        <div
                                            className={styles.methodItem}
                                            onClick={handleMethodItemClick}
                                            ref={methodItemRef}
                                        >
                                            <div
                                                ref={methodItemRef}
                                                onClick={handleMethodItemClick}
                                                className={styles.withDrawItemLeft}
                                            >
                                                {selectedToken === "USDT" ? (
                                                    <img
                                                        ref={methodItemRef}
                                                        onClick={handleMethodItemClick}
                                                        className={styles.withdrawImg}
                                                        src={usdtImg}
                                                        alt="usdtImg"
                                                    />
                                                ) : selectedToken === "USDC" ? (
                                                    <img
                                                        ref={methodItemRef}
                                                        onClick={handleMethodItemClick}
                                                        className={styles.withdrawImg}
                                                        src={usdcImg}
                                                        alt="usdcImg"
                                                    />
                                                ) : selectedToken === "CASH" ? (
                                                    <img
                                                        ref={methodItemRef}
                                                        onClick={handleMethodItemClick}
                                                        className={styles.withdrawImg}
                                                        src={usdImg}
                                                        alt="cashImg"
                                                    />
                                                ) : null}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "space-around",
                                                        background: "#1c1c1c"
                                                    }}
                                                    onClick={handleMethodItemClick}
                                                    ref={methodItemRef}
                                                >
                                                    <h4>Redeem to</h4>
                                                    {selectedToken === "CASH" ? (
                                                        <h5>Cash</h5>) : (
                                                        <h5>{shortenAddress(walletAddress)}</h5>)}
                                                </div>
                                            </div>
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <div
                                                    className={styles.withDrawItemRight}
                                                >
                                                    <h5>
                                                        {selectedToken === "CASH"
                                                            ? '2 days'
                                                            : (parseFloat(typedMintValue) || 0) >= 5000
                                                                ? '1 day'
                                                                : 'Instant'
                                                        }
                                                    </h5>

                                                    <h5 onClick={handleMethodItemClick} ref={methodItemRef}>
                                                        {selectedToken === "CASH" ? (
                                                            <h5><span
                                                                className={styles.payWithValue}
                                                            >
                                                        {Number(
                                                            (
                                                                Math.floor(parseFloat(typedMintValue) *(100-networkFeePercent)) / 100
                                                            ).toFixed(2)
                                                        )}
                                                    </span>
                                                                {' '} USD</h5>
                                                        ) : (
                                                            <h5> <span
                                                                className={styles.payWithValue}
                                                            >
                                                        {typedMintValue}
                                                    </span>
                                                                {' '}{selectedToken}</h5>)}

                                                    </h5>
                                                </div>
                                                {showTokenSelector ? (
                                                    <img
                                                        src={dropDownImg}
                                                        className={styles.arrowDown}
                                                        alt="arrow"
                                                        onMouseEnter={(e) => (e.currentTarget.src = dropDownHoverImg)}
                                                        onMouseLeave={(e) => (e.currentTarget.src = dropDownImg)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={dropDownRightImg}
                                                        className={styles.arrowDown}
                                                        alt="arrow"
                                                        onMouseEnter={(e) => (e.currentTarget.src = dropDownRightHoverImg)}
                                                        onMouseLeave={(e) => (e.currentTarget.src = dropDownRightImg)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Token Selector Modal (Redeem) */}
                                    {showTokenSelector && (
                                        <div ref={tokenSelectorRef} className={styles.tokenSelectorModal}>
                                            <div className={styles.tokenSelectorTitleContainer}>
                                                <div className={styles.tokenSelectorTitle}>Token</div>
                                                <div className={styles.tokenSelectorTitle}>Balance/Address</div>
                                            </div>
                                            <div className={styles.tokenSelectorContentContainer}>

                                                {/* Cash Row */}
                                                <div
                                                    className={
                                                        selectedToken === 'CASH'
                                                            ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                            : styles.tokenSelectorContent
                                                    }
                                                    style={
                                                        selectedToken === 'CASH'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                    onClick={() => handleSelectToken("CASH")}
                                                >
                                                    <div
                                                        style={{
                                                            background: selectedToken === 'CASH'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                        className={styles.tokenSelectorContentLeft}
                                                    >
                                                        <img
                                                            className={styles.selectorImg}
                                                            style={{
                                                                background: selectedToken === 'CASH'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            src={usdImg}
                                                            alt="wireTransferImg"
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                background: selectedToken === 'CASH'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    marginBottom: '3px',
                                                                    background: selectedToken === 'CASH'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                CASH
                                                            </h4>
                                                            <h5
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'CASH'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USD Cash
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={styles.tokenSelectorWalletContainer}
                                                        style={{
                                                            background: selectedToken === 'CASH'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >

                                                        <div
                                                            style={{
                                                                background: selectedToken === 'CASH'
                                                                    ? '#2b3336'
                                                                    : 'transparent',
                                                                textAlign: "right"
                                                            }}
                                                        >
                                                            --
                                                        </div>

                                                        <div
                                                            style={{
                                                                background: selectedToken === 'CASH'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                        <span
                                                            className={styles.tokenSelectorContentRightGrey}
                                                            style={{
                                                                background: selectedToken === 'CASH'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            C2C Network
                                                        </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* USDC Row */}
                                                <div
                                                    className={
                                                        selectedToken === 'USDC'
                                                            ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                            : styles.tokenSelectorContent
                                                    }
                                                    style={
                                                        selectedToken === 'USDC'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                    onClick={() => handleSelectToken("USDC")}
                                                >
                                                    <div
                                                        style={{
                                                            background: selectedToken === 'USDC'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                        className={styles.tokenSelectorContentLeft}
                                                    >
                                                        <img
                                                            className={styles.selectorImg}
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            src={usdcImg}
                                                            alt="wireTransferImg"
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    marginBottom: '3px',
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDC
                                                            </h4>
                                                            <h5
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USD Coin
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={styles.tokenSelectorWalletContainer}
                                                        style={{
                                                            background: selectedToken === 'USDC'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        {usdcBalance ? (
                                                            <div
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                className={styles.tokenSelectorContentRight}
                                                            >
                                                                {usdcBalance}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent',
                                                                    textAlign: "right"
                                                                }}
                                                            >
                                                                --
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                        <span
                                                            className={styles.tokenSelectorContentRightGrey}
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                        >
                                                            {shortenAddress(usdcTokenAddress)}
                                                        </span>
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                            {justCopiedUSDC ? (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    style={{
                                                                        background: selectedToken === 'USDC'
                                                                            ? '#2b3336'
                                                                            : 'transparent',
                                                                        width: '11px',
                                                                        height: '11px'
                                                                    }}
                                                                    src={copyConfirmImg}
                                                                    alt="Copied!"
                                                                />
                                                            ) : (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    style={{
                                                                        background: selectedToken === 'USDC'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    src={copySelectorImg}
                                                                    onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                                    alt="copy img"
                                                                    onMouseEnter={(ev) =>
                                                                        (ev.currentTarget.src = copySelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(ev) =>
                                                                        (ev.currentTarget.src = copySelectorImg)
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                            <img
                                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                                src={shareSelectorImg}
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                alt="share img"
                                                                onClick={(e) => handleShare(e, usdcTokenAddress)}
                                                                onMouseEnter={(ev) =>
                                                                    (ev.currentTarget.src = shareSelectorHoverImg)
                                                                }
                                                                onMouseLeave={(ev) =>
                                                                    (ev.currentTarget.src = shareSelectorImg)
                                                                }
                                                            />
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* USDT Row */}
                                                <div
                                                    className={
                                                        selectedToken === 'USDT'
                                                            ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                            : styles.tokenSelectorContent
                                                    }
                                                    style={{
                                                        background: selectedToken === 'USDT'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                    onClick={() => handleSelectToken("USDT")}
                                                >
                                                    <div
                                                        className={styles.tokenSelectorContentLeft}
                                                        style={{
                                                            background: selectedToken === 'USDT'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            className={styles.selectorImg}
                                                            src={usdtImg}
                                                            alt="wireTransferImg"
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    marginBottom: '0px',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDT
                                                            </h4>
                                                            <h5
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDT
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={styles.tokenSelectorWalletContainer}
                                                        style={{
                                                            background: selectedToken === 'USDT'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        {usdtBalance ? (
                                                            <div
                                                                className={styles.tokenSelectorContentRight}
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                {usdtBalance}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent',
                                                                    textAlign: 'right'
                                                                }}
                                                            >
                                                                --
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                marginBottom: '0',
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                        <span
                                                            className={styles.tokenSelectorContentRightGrey}
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                                        >
                                                            {shortenAddress(usdtTokenAddress)}
                                                        </span>
                                                            <span
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                            {justCopiedUSDT ? (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copyConfirmImg}
                                                                    alt="Copied!"
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent',
                                                                        width: '11px',
                                                                        height: '11px'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copySelectorImg}
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    alt="copy img"
                                                                    onClick={(ev) => handleCopy(ev, usdtTokenAddress, 'USDT')}
                                                                    onMouseEnter={(ev) =>
                                                                        (ev.currentTarget.src = copySelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(ev) =>
                                                                        (ev.currentTarget.src = copySelectorImg)
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                                            <span
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                            <img
                                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                                src={shareSelectorImg}
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                alt="share img"
                                                                onClick={(ev) => handleShare(ev, usdtTokenAddress)}
                                                                onMouseEnter={(ev) =>
                                                                    (ev.currentTarget.src = shareSelectorHoverImg)
                                                                }
                                                                onMouseLeave={(ev) =>
                                                                    (ev.currentTarget.src = shareSelectorImg)
                                                                }
                                                            />
                                                        </span>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    )}


                                    {walletAddress ? <button onClick={handleRedeemUSDi}>
                                            Redeem USDi
                                        </button> :
                                        <button onClick={handleConnectWallet}>Connect Wallet</button>}

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LayoutGroup>
    );
};

export default SwapMainAPP;
