import React from 'react';
import styles from './C2COrderDetail.module.css';

import AcceptIcon from '../static_files/AcceptOrder.svg';
import USDIcon from '../static_files/usd.svg';
import KraIcon from '../static_files/KRA.svg'

function C2COrderDetail({
                            cashDeliveryAmount,   // e.g. "$98,000 USD Cash"
                            orderTips,            // e.g. "30,000 KRA ($1,000.12)"
                            fulfillDeadline,      // e.g. "01/25/2025, 14:00"
                            collateralRequired,   // e.g. "20,000 KRA"
                            rate,                 // e.g. "1 KRA=0.05 USDi"
                            collateralRatio,      // e.g. "200%"
                            onConfirm,
                            onClose,
                            isFulfillOrder = true          // true or false used to determine 是用来确认fulfill order还是 cash received
                        }) {
    return (
        <div className={styles.modalOverlay}>
            <div className={styles.transactionDetailModal}>

                {/* Modal header */}
                <div className={styles.modalHeader}>
                    <h2>Transaction detail</h2>
                    <div
                        className={styles.closeBtn}
                        onClick={onClose}
                        aria-label="Close"
                    >
                        ×
                    </div>
                </div>

                <div className={styles.lineWrapper}>
                    <div className={styles.dividerLineFull}></div>
                </div>

                {/* Body content */}
                <div className={styles.modalBody}>
                    <div className={styles.statusSection}>
                        <div className={styles.statusImageContainer}>
                            <img
                                src={AcceptIcon}
                                alt="Status complete"
                                className={styles.statusIcon}
                            />
                        </div>
                        {/* Changed from "In progress" to "Accept the Order" */}
                        {isFulfillOrder? <div className={styles.statusText}>Accept the Order</div>:
                            <div className={styles.statusText}>Cash Received</div>}
                    </div>

                    {/* Reused the same transactionAmounts area but changed labels */}
                    <div className={styles.transactionAmounts}>
                        {/* Using transactionFrom for “Cash Delivery Amount” */}
                        <div className={styles.transactionFrom}>
                            <span className={styles.label}>Cash Delivery Amount</span>
                            <div className={styles.amountRow}>
                                <img src={USDIcon} alt="Cash Delivery"/>
                                <span className={styles.greenAmount}>{cashDeliveryAmount}</span>
                            </div>
                        </div>

                        {/* Using transactionTo for “Order Tips” */}
                        <div className={styles.transactionTo}>
                            <span className={styles.label}>Order Tips</span>
                            <div className={styles.amountRow}>
                                <img src={KraIcon} alt="Tips"/>
                                <span className={styles.amount}>{orderTips}</span>
                            </div>
                        </div>
                    </div>

                    {/* Transaction details (kept the same classes) */}
                    <div className={styles.transactionDetails}>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Transaction type</span>
                            <span className={styles.detailValue}>Redeem</span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Fulfill deadline</span>
                            <span className={styles.detailValue}>{fulfillDeadline}</span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Network</span>
                            <span className={styles.detailValue}>C2C</span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Collateral Required</span>
                            <span className={styles.detailValue}>{collateralRequired}</span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Rate</span>
                            <span className={styles.detailValue}>1 KRA = {Number(rate).toFixed(8)} USDi </span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Collateral Ratio</span>
                            <span className={styles.detailValue}>{collateralRatio}</span>
                        </div>
                    </div>

                    <div className={styles.lineWrapper}>
                        <div className={styles.dividerLineFull}></div>
                    </div>

                    {/* Bottom notes remain the same */}
                    <div className={styles.transactionNotes}>
                        {isFulfillOrder?
                        <p>The KRA collateral will be returned after cash delivery and requestor confirmation.</p>
                            :<p>Once you have confirmed the cash received, KRA collateral will be released back to the node operator. </p>}
                    </div>
                </div>

                {/* Modal footer */}
                <div className={styles.modalFooter}>
                    <button
                        type="button"
                        onClick={onConfirm}
                        className={styles.confirmBtn}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default C2COrderDetail;
