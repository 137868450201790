import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import {AuthProvider, useAuth} from './AuthContext';
import {SwapMainAppProvider} from "./SwapMainAppContext";
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import GatewayTokenVerifier from './GatewayTokenVerifier';
import SwapMainAPP from "./tables/SwapMainApp";
import TransactionHistory from "./tables/TransactionHistory";
import C2CNetwork from "./tables/C2C NetWork/C2CNetwork";
import EmailSwapMainAPP from "./tables/EmailSwapMainAPP/EmailSwapMainAPP";
import Account from "./tables/Account/Account";

const AdminProtectedRoute = ({children}) => {
    const {isAuthenticated} = useAuth();
    return isAuthenticated ? children : <Navigate to="/"/>;
};

const GatewayProtectedRoute = ({children}) => {
    const {isGatewayAuthenticated} = useAuth();
    return isGatewayAuthenticated ? children : <Navigate to="/"/>;
};

const App = () => {
    return (
        <AuthProvider>
            <SwapMainAppProvider>
                <Router>
                    <Routes>
                        {/* Admin login route */}

                        <Route path="/" element={<SwapMainAPP/>}/>
                        <Route path="/transaction" element={<TransactionHistory/>}/>
                        <Route path="/c2c" element={<C2CNetwork/>}/>
                        <Route path="/account" element={<Account/>}/>
                        <Route path="/admin" element={<LoginPage/>}/>
                        {/* Gateway route: verify token before showing Swap */}
                        <Route
                            path="/swap"
                            element={
                                <GatewayTokenVerifier>
                                    <GatewayProtectedRoute>
                                        <EmailSwapMainAPP/>
                                    </GatewayProtectedRoute>
                                </GatewayTokenVerifier>
                            }
                        />

                        {/* Admin protected routes */}
                        <Route
                            path="/home/*"
                            element={
                                <AdminProtectedRoute>
                                    <HomePage/>
                                </AdminProtectedRoute>
                            }
                        />

                        {/* Catch-all redirect */}
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </Router>
            </SwapMainAppProvider>
        </AuthProvider>
    );
};

export default App;
