import React, { createContext, useEffect, useState } from 'react';
import { fetchC2CNetworkConfig } from './tables/utils';

export const SwapMainAppContext = createContext(null);

export function SwapMainAppProvider({ children }) {
  const [notification, setNotification] = useState({
    status: null,
    message: null,
  });

  function showNotification(status, message) {
    setNotification({ status, message });
    setTimeout(() => {
      setNotification({ status: null, message: null });
    }, 5000);
  }

  // 1) Create state to store the fee
  const [networkFeePercent, setNetworkFeePercent] = useState(0);
  const [collateralRatio, setCollateralRatio] = useState(0);
  // 2) Fetch the  net work fee percentage  once when the component mounts
  useEffect(() => {
    //e.g.
    //{'config_id': 1, 'collateral_ratio': Decimal('200.00'), 'network_fee_percentage': Decimal('1.00')}]
    async function loadFee() {
      try {
        const feePercent = await fetchC2CNetworkConfig();
        setNetworkFeePercent(feePercent.network_fee_percentage );
        setCollateralRatio(feePercent.collateral_ratio)
      } catch (err) {
        console.error('Error fetching network fee:', err);
      }
    }
    loadFee();
  }, []);

  // 3) Pass the fee down in context value
  return (
    <SwapMainAppContext.Provider
      value={{
        notification,
        showNotification,
        networkFeePercent,  // <-- Provide it here
        collateralRatio,
      }}
    >
      {children}
    </SwapMainAppContext.Provider>
  );
}