import React, {useContext, useEffect, useState} from 'react';
import styles from './Account.module.css';
import ConnectWalletHeader from '../header/ConnectWalletHeader';
import LeftPanel from '../SwapLeftPanel/LeftPanel';
import {SwapMainAppContext} from '../../SwapMainAppContext';
import {shortenAddress} from '../utils';

import accountIcon from '../static_files/AccountIcon.svg';
import infoIcon from '../static_files/info.svg';

import {USDI_API_URL} from '../../configs/config'
import {check_input} from "../utils";

function Account() {
    const [showPanel, setShowPanel] = useState(false);
    const {showNotification} = useContext(SwapMainAppContext);

    // The main wallet address to display
    const [walletAddress, setWalletAddress] = useState('');
    // For the top-right button or email-based connection
    const [emailWalletAddress, setEmailWalletAddress] = useState(null);

    // Contact info
    const [contactInfo, setContactInfo] = useState({
        telegram: '--',
        twitter: '--',
        phone: '--',
        email: '--',
    });

    // Track which field is in edit mode
    // { telegram: false, twitter: false, phone: false, email: false }
    const [isEditing, setIsEditing] = useState({});

    useEffect(() => {
        // If there's an email-based wallet address get the address
        const storedAddress = localStorage.getItem('emailUserWalletAddress');
        if (storedAddress) {
            setWalletAddress(storedAddress);
            setEmailWalletAddress(storedAddress);
        }
    }, []);

    // ====== FETCH from /usdi_holders_info_table_solana when walletAddress is known ======
    useEffect(() => {
        if (!walletAddress) return;

        const fetchContactInfo = async () => {
            try {
                if (!walletAddress || walletAddress.trim() === "" || walletAddress === "null" || walletAddress === "--") {
                    setWalletAddress("--")
                    return;
                }
                const res = await fetch(
                    `${USDI_API_URL}/usdi_holders_info_table_solana?address=${walletAddress}`
                );
                // console.log("res", res.json());
                if (res.status === 404) {
                    return;
                }
                if (!res.ok) {
                    throw new Error(`Error fetching user data: ${res.statusText}`);
                }
                const data = await res.json();
                if (!data) {
                    return;
                }
                setContactInfo({
                    email: data[0].email ?? '--',
                    twitter: data[0].twitter_id ?? '--',
                    phone: data[0].phone_number ?? '--',
                    telegram: data[0].telegram_id ?? '--',
                });
            } catch (err) {
                console.error(err);
                showNotification(`Error fetching account info: ${err}`, 'error');
            }
        };

        fetchContactInfo();
    }, [walletAddress, showNotification]);

    // For Payment Methods
    const [paymentMethods, setPaymentMethods] = useState([]);

    // ====== Payment Method Removal Modal State ======
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [pendingRemoveId, setPendingRemoveId] = useState(null);

    // Distinguish this modal from contact editing (which is now inline)
    // We only use the modal for removing payment methods now
    const handleRemoveClick = (id) => {
        setPendingRemoveId(id);
        setShowRemoveModal(true);
    };

    const handleConfirmRemove = () => {
        if (pendingRemoveId != null) {
            setPaymentMethods((prev) => prev.filter((m) => m.id !== pendingRemoveId));
        }
        setPendingRemoveId(null);
        setShowRemoveModal(false);
    };

    const handleCloseModal = () => {
        setPendingRemoveId(null);
        setShowRemoveModal(false);
    };

    // ====== Payment Method Add ======
    const handleAddPaymentMethod = () => {
        const newMethod = {
            id: Date.now(),
            name: 'Bank of Test',
            accountInfo: 'Checking ••••1234',
        };
        console.log("payment", paymentMethods)
        setPaymentMethods([...paymentMethods, newMethod]);
    };

    // ====== Inline Editing for Contact Info ======
    // Toggles the "isEditing" state for a given field (telegram, twitter, phone, email).
    const handleToggleEdit = (field) => {
        setIsEditing((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    // Save data for a single field
    const handleSaveField = async (field) => {
        try {

            let updatedValue = contactInfo[field].trim();
            if (updatedValue === "") {
                updatedValue = "--";
            }
            if (updatedValue !== contactInfo[field]) {
                setContactInfo((prev) => ({
                    ...prev,
                    [field]: updatedValue,
                }));
            }
            const isValid = check_input(field, contactInfo[field]);

            if (!isValid) {
                showNotification('error', `Invalid ${field} input`);
                return; // Stop here if invalid
            }
            const fieldMap = {
                email: 'email',
                twitter: 'twitter_id',
                phone: 'phone_number',
                telegram: 'telegram_id',
            };

            const updatePayload = {
                [fieldMap[field]]: contactInfo[field] === '' ? '--' : contactInfo[field],
            };

            console.log("updatedpaload", updatePayload)
            const url = `${USDI_API_URL}/update?table=usdi_holders_info_table_solana&key=address&value=${walletAddress}`;
            // 4) Do the fetch call
            const response = await fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(updatePayload),
            });
            console.log("got hree")
            if (!response.ok) {
                // Possibly read error message:
                const errData = await response.json();
                throw new Error(errData.error || 'Failed to update.');
            }

            // 5) If successful, finalize the UI changes:
            setIsEditing((prev) => ({...prev, [field]: false}));
            // Optionally show a success notification...
            showNotification('success', 'Contact info updated!');
        } catch (err) {
            console.error(err);
            showNotification('error', `Error updating ${field}: ${err.message}`);
        }
    };

    // Helper to handle changes in the text inputs
    const handleFieldChange = (field, newValue) => {
        setContactInfo((prev) => ({
            ...prev,
            [field]: newValue.trim() === '' ? '--' : newValue,
        }));
    };

    const getButtonLabel = (field) => {
        const isFieldEmpty = contactInfo[field] === '--';
        const inEditMode = !!isEditing[field];

        if (inEditMode) {
            return 'Save';
        } else {
            return isFieldEmpty ? 'Add' : 'Edit';
        }
    };

    return (
        <div className={styles.mainContainer}>
            <LeftPanel/>

            <div className={styles.RightPanelOuterContainer}>
                <div className={styles.rightPanel}>
                    <ConnectWalletHeader
                        onWalletConnected={(publicKey) => setWalletAddress(publicKey)}
                        showPanel={showPanel}
                        setShowPanel={setShowPanel}
                        emailWallet={emailWalletAddress}
                    />

                    <div className={styles.accountTitle}>Account</div>

                    <div className={styles.accountPage}>
                        {/* User Info (Top card) */}
                        <div className={styles.userInfoContainer}>
                            <div className={styles.userInfoContent}>
                                <img src={accountIcon} alt="AccountImg"/>
                                <div className={styles.userInfo}>
                                    <div className={styles.userWallet} style={{fontSize: '20px'}}>
                                        {walletAddress ? shortenAddress(walletAddress) : '--'}
                                    </div>
                                    {isEditing.email ? (
                                        <input
                                            type="text"
                                            className={styles.modalInput}
                                            value={contactInfo.email === '--' ? '' : contactInfo.email}
                                            onChange={(e) => handleFieldChange('email', e.target.value)}
                                        />
                                    ) : (
                                        <div className={styles.greyText}>
                                            {contactInfo.email === '--' ? '--' : contactInfo.email}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Inline editing for email (button toggles editing) */}
                            <button
                                className={styles.editButton}
                                onClick={() =>
                                    isEditing.email
                                        ? handleSaveField('email')
                                        : handleToggleEdit('email')
                                }
                            >
                                {getButtonLabel('email')}
                            </button>
                        </div>

                        <hr className={styles.divider}/>

                        {/* Contact Info */}
                        <div className={styles.contactInfoContainer}>
                            <h2>Contact Info</h2>

                            {/* Telegram Row */}
                            <div
                                className={styles.userInfoContainer}
                                style={{marginBottom: '28px'}}
                            >
                                <div className={styles.userInfoContent}>
                                    <div className={styles.userInfo}>
                                        <div className={styles.userWallet}>Telegram ID</div>

                                        {/* If editing, show input; otherwise show text */}
                                        {isEditing.telegram ? (
                                            <input
                                                type="text"
                                                className={styles.modalInput}
                                                value={contactInfo.telegram === '--' ? '' : contactInfo.telegram}
                                                onChange={(e) =>
                                                    handleFieldChange('telegram', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <div className={styles.greyText}>{contactInfo.telegram}</div>
                                        )}
                                    </div>
                                </div>

                                {/* Add/Edit or Save button */}
                                <button
                                    className={styles.editButton}
                                    onClick={() =>
                                        isEditing.telegram
                                            ? handleSaveField('telegram')
                                            : handleToggleEdit('telegram')
                                    }
                                >
                                    {getButtonLabel('telegram')}
                                </button>
                            </div>

                            {/* Twitter Row */}
                            <div
                                className={styles.userInfoContainer}
                                style={{marginBottom: '28px'}}
                            >
                                <div className={styles.userInfoContent}>
                                    <div className={styles.userInfo}>
                                        <div className={styles.userWallet}>Twitter ID</div>

                                        {isEditing.twitter ? (
                                            <input
                                                type="text"
                                                className={styles.modalInput}
                                                value={contactInfo.twitter === '--' ? '' : contactInfo.twitter}
                                                onChange={(e) =>
                                                    handleFieldChange('twitter', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <div className={styles.greyText}>{contactInfo.twitter}</div>
                                        )}
                                    </div>
                                </div>

                                <button
                                    className={styles.editButton}
                                    onClick={() =>
                                        isEditing.twitter
                                            ? handleSaveField('twitter')
                                            : handleToggleEdit('twitter')
                                    }
                                >
                                    {getButtonLabel('twitter')}
                                </button>
                            </div>

                            {/* Phone Row */}
                            <div className={styles.userInfoContainer}>
                                <div className={styles.userInfoContent}>
                                    <div className={styles.userInfo}>
                                        <div className={styles.userWallet}>Phone Number</div>

                                        {isEditing.phone ? (
                                            <input
                                                type="text"
                                                className={styles.modalInput}
                                                value={contactInfo.phone === '--' ? '' : contactInfo.phone}
                                                onChange={(e) =>
                                                    handleFieldChange('phone', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <div className={styles.greyText}>{contactInfo.phone}</div>
                                        )}
                                    </div>
                                </div>

                                <button
                                    className={styles.editButton}
                                    onClick={() =>
                                        isEditing.phone
                                            ? handleSaveField('phone')
                                            : handleToggleEdit('phone')
                                    }
                                >
                                    {getButtonLabel('phone')}
                                </button>
                            </div>
                        </div>

                        <hr className={styles.divider2}/>

                        {/* Payment Methods */}
                        <div className={styles.paymentMethodsContainer}>
                            <h2>Payment Methods</h2>

                            {paymentMethods.map((method) => (
                                <div key={method.id} className={styles.userInfoContainer}>
                                    <div
                                        className={styles.userInfoContent}
                                        style={{marginBottom: '23px'}}
                                    >
                                        <img src={accountIcon} alt="AccountImg"/>
                                        <div className={styles.userInfo}>
                                            <div className={styles.userWallet}>{method.name}</div>
                                            <div className={styles.greyText}>{method.accountInfo}</div>
                                        </div>
                                    </div>
                                    <button
                                        className={styles.removeButton}
                                        onClick={() => handleRemoveClick(method.id)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}

                            <button
                                className={styles.addPaymentButton}
                                style={{marginTop: '35px', marginBottom: '45px'}}
                                onClick={handleAddPaymentMethod}
                            >
                                Add a Payment Method
                            </button>

                            {paymentMethods.length === 0 ? (
                                <div className={styles.addPaymentInfo}>
                                    <img src={infoIcon} alt="info"/>
                                    Add a payment method to access the C2C network.
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            {/* ========== ONLY Payment-Method Removal Modal ========== */}
            {showRemoveModal && (
                <>
                    <div className={styles.modalOverlay} onClick={handleCloseModal}/>
                    <div className={styles.modalContainer}>
                        <button className={styles.modalCloseBtn} onClick={handleCloseModal}>
                            &times;
                        </button>
                        <div className={styles.modalTitle}>Delete Account?</div>
                        <hr className={styles.modalDivider}/>
                        <div className={styles.modalMessage}>
                            Are you sure you want to remove this linked account?
                        </div>
                        <div className={styles.modalActions}>
                            <button className={styles.cancelBtn} onClick={handleCloseModal}>
                                Cancel
                            </button>
                            <button className={styles.confirmBtn} onClick={handleConfirmRemove}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Account;