// Base Url
export const ENDPOINT_BASE_URL = 'https://max1-funding-arb.uc.r.appspot.com';

export const USDI_API_URL = 'https://usdi-api-jlukzyz7wa-ew.a.run.app/';

export const USDI_MINT_ADDRESS_SOLANA = "CXbKtuMVWc2LkedJjATZDNwaPSN6vHsuBGqYHUC4BN3B";

export const RPC_URL = "https://solitary-boldest-thunder.solana-mainnet.quiknode.pro/7e14722814e9fc29ad81532c2da66c2ffbe3781f";
// const RPC_URL = "https://rpc.ankr.com/solana/80465c767c6a5751c4cadf0778b6917d26c2d0a9ae583d3616ffaae71191a7c4";

export const USDT_MINT_ADDRESS_SOLANA = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB';
export const USDC_MINT_ADDRESS_SOLANA = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';
export const KIRA_MINT_ADDRESS_SOLANA = 'AY2Qidgrky4TFymeJKe36w5buY7QWK7TSd7obQLupump';


// config.js
let configCache = {
  squadsPublicKey: '',
  usdcTokenAddress: '',
  usdtTokenAddress: '',
  c2c_redeem_network_fee_percentage: '',
  isFetched: false, // 标记是否已经请求过
};

// 用于初始化、请求后台、保存结果
export async function initConfig() {
  if (configCache.isFetched) {
    return;
  }

  try {
    // 1) 获取 squads 公钥
    const res = await fetch(
      "https://usdi-api-256611876551.europe-west1.run.app/usdi_squads_public_key"
    );
    if (!res.ok) {
      throw new Error("Failed to fetch squads public key");
    }
    const data = await res.json();
    if (data && data.USDi_Squads_Public_Key) {
      configCache.squadsPublicKey = data.USDi_Squads_Public_Key;
    }

    // 2) 获取 USDC address
    const res1 = await fetch(
      "https://usdi-api-256611876551.europe-west1.run.app/verified_token_solana_usdc"
    );
    if (!res1.ok) {
      throw new Error("Failed to fetch verified_token_solana_usdc");
    }
    const data1 = await res1.json();
    if (data1 && data1.verified_token_solana_usdc) {
      configCache.usdcTokenAddress = data1.verified_token_solana_usdc;
    }

    // 3) 获取 USDT address
    const res2 = await fetch(
      "https://usdi-api-256611876551.europe-west1.run.app/verified_token_solana_usdt"
    );
    if (!res2.ok) {
      throw new Error("Failed to fetch verified_token_solana_usdt");
    }
    const data2 = await res2.json();
    if (data2 && data2.verified_token_solana_usdt) {
      configCache.usdtTokenAddress = data2.verified_token_solana_usdt;
    }

    // 4) 获取 c2c_redeem_config_solana
    const res3 = await fetch(
      "https://usdi-api-256611876551.europe-west1.run.app/c2c_redeem_config_solana"
    );
    if (!res3.ok) {
      throw new Error("Failed to fetch c2c_redeem_config_solana");
    }
    const data3 = await res3.json();
    // 只要 data3 里有 network_fee_percentage，就写进 configCache
    if (data3 && data3.network_fee_percentage) {
      configCache.c2c_redeem_network_fee_percentage = data3.network_fee_percentage;
    }

    configCache.isFetched = true;
  } catch (error) {
    console.error("Error fetching config:", error);
    // 如果需要，也可以抛出错误，让上层处理
    throw error;
  }
}

// 获取当前内存中的配置
export function getConfig() {
  return {
    squadsPublicKey: configCache.squadsPublicKey,
    usdcTokenAddress: configCache.usdcTokenAddress,
    usdtTokenAddress: configCache.usdtTokenAddress,
    c2c_redeem_network_fee_percentage: configCache.c2c_redeem_network_fee_percentage,
  };
}