// raydium-swap.js

import {PoolUtils} from '@raydium-io/raydium-sdk-v2'
import BN from 'bn.js'
import raydiumConfig from './raydium-config.js'
import {isValidClmm} from './utils.js'

/**
 * Swap on Raydium CLMM for a specific pool.
 *
 * @param {Object} options
 * @param {string}  [options.transactionType="mint"] - "mint" 或 "redeem"
 * @param {string}  [options.tokenType="usdt"]       - "usdt" 或 "usdc"
 * @param {number}  [options.amount=100]            - UI 上输入的数量
 */
export async function raydiumSwap({
                                      transactionType = "mint",
                                      tokenType = "usdt",
                                      amount = 100,
                                  } = {}) {
    const raydium = await raydiumConfig.initSdk();

    // Define swap settings for each token and transaction type
    const config = {
        USDC: {
            mint: {
                poolId: "6bGe466weTDXkv8emyRMxFxLDQyXkE7W89zod8e5AGVe", // USDi<->USDC
                inputMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", // USDC
            },
            redeem: {
                poolId: "6bGe466weTDXkv8emyRMxFxLDQyXkE7W89zod8e5AGVe", // USDT<->USDi
                inputMint: "CXbKtuMVWc2LkedJjATZDNwaPSN6vHsuBGqYHUC4BN3B", // USDi
            },
        },
        USDT: {
            mint: {
                poolId: "HGd3fN56depADgFobWkhiQ2hFmFxCmas3Ysmtn6wCXTh", // USDT -> USDi
                inputMint: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
            },
            redeem: {
                poolId: "HGd3fN56depADgFobWkhiQ2hFmFxCmas3Ysmtn6wCXTh", // USDi -> USDT
                inputMint: "CXbKtuMVWc2LkedJjATZDNwaPSN6vHsuBGqYHUC4BN3B",
            },
        },
    };

    // Normalize tokenType (e.g., "usdt" becomes "USDT")
    const normalizedToken = tokenType.toUpperCase();
    const swapConfig = config[normalizedToken]?.[transactionType];
    if (!swapConfig) {
        throw new Error(
            `Invalid tokenType (${tokenType}) or transactionType (${transactionType}).`
        );
    }

    // Execute the swap with the determined configuration
    return doSingleSwap({
        raydium,
        poolId: swapConfig.poolId,
        inputMint: swapConfig.inputMint,
        amount,
    });
}

/**
 * 执行一次 CLMM Swap (单次)
 * @param {Object} params
 * @param {any}   params.raydium    - 已初始化的 Raydium 实例
 * @param {string} params.poolId    - 池子 ID
 * @param {string} params.inputMint - 输入币 mint
 * @param {number} params.amount    - 输入数量
 * @returns { success:boolean, txId?:string, code?:string, message?:string }
 */
async function doSingleSwap({raydium, poolId, inputMint, amount}) {
    try {
        // 1) 获取池信息
        const inputAmount = new BN(amount * 10 ** 6)
        console.log('SingleSwap:', {poolId, inputMint, amount})

        let poolInfo, poolKeys, clmmPoolInfo, tickCache
        if (raydium.cluster === 'mainnet') {
            const data = await raydium.api.fetchPoolById({ids: poolId})
            poolInfo = data[0]

            if (!isValidClmm(poolInfo.programId)) {
                throw new Error(`Target pool is not a CLMM pool: poolId=${poolId}`)
            }

            clmmPoolInfo = await PoolUtils.fetchComputeClmmInfo({
                connection: raydium.connection,
                poolInfo,
            })

            tickCache = await PoolUtils.fetchMultiplePoolTickArrays({
                connection: raydium.connection,
                poolKeys: [clmmPoolInfo],
            })

        } else {
            // devnet ...
            const data = await raydium.clmm.getPoolInfoFromRpc(poolId)
            poolInfo = data.poolInfo
            poolKeys = data.poolKeys
            clmmPoolInfo = data.computePoolInfo
            tickCache = data.tickData
        }
        // 2) 校验 inputMint 是否在池里
        if (
            inputMint !== poolInfo.mintA.address &&
            inputMint !== poolInfo.mintB.address
        ) {
            throw new Error(`Input mint ${inputMint} not in pool ${poolId}`)
        }

        // 3) 计算可得
        const baseIn = (inputMint === poolInfo.mintA.address)
        const {minAmountOut, remainingAccounts} = await PoolUtils.computeAmountOutFormat({
            poolInfo: clmmPoolInfo,
            tickArrayCache: tickCache[poolId],
            amountIn: inputAmount,
            tokenOut: poolInfo[baseIn ? 'mintB' : 'mintA'],
            slippage: 0.01,
            epochInfo: await raydium.fetchEpochInfo(),
        })

        // 4) swap
        const {execute} = await raydium.clmm.swap({
            poolInfo,
            poolKeys,
            inputMint: poolInfo[baseIn ? 'mintA' : 'mintB'].address,
            amountIn: inputAmount,
            amountOutMin: minAmountOut.amount.raw,
            observationId: clmmPoolInfo.observationId,
            ownerInfo: {useSOLBalance: true},
            remainingAccounts,
            txVersion: raydiumConfig.txVersion,
        })

        // 6) 发送交易 + 捕获错误 / 返回结果
        try {
            const {txId} = await execute()
            console.log(`Swapped in CLMM pool: https://solscan.io/tx/${txId}`)
            return {success: true, txId}
        } catch (err) {
            console.error('Swap transaction failed:', err)
            return {
                success: false,
                code: err.code ?? 'SWAP_ERROR',
                message: err.message ?? 'Unknown swap error'
            }
        }
    } catch (error) {
        // 如果在获取池信息或计算阶段就报错，也要返回
        console.error('SingleSwap error:', error)
        return {
            success: false,
            code: error.code ?? 'SWAP_PREPARE_ERROR',
            message: error.message ?? 'Unknown prepare error'
        }
    }
}
