import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './LeftPanel.module.css';

import logo_svg from '../static_files/logo-nobackground.svg';
import transImg from '../static_files/My Assets.svg';
import assetsImg from '../static_files/Transactions.svg';
import c2cImg from '../static_files/C2C Network.svg';
import inviteImg from '../static_files/Invite-friends.svg';
import accountImg from '../static_files/Account_leftPanel.svg'
const LeftPanel = () => {
    const [currentPath, setCurrentPath] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, []);

    const handleMyAssetsClick = () => {
        const token = localStorage.getItem('gatewayToken');
        if (token) {
            navigate(`/swap?token=${token}`);
        } else {
            navigate('/');
        }
    };

    return (
        <div className={styles.leftPanel}>
            {/* Logo */}
            <div className={styles.topLeftImageWrapper}>
                <a
                    style={{backgroundColor: '#04070D'}}
                    href="https://0max1.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        style={{backgroundColor: '#04070D'}}
                        className={styles.vectorIcon}
                        alt="Logo"
                        src={logo_svg}
                    />
                </a>
            </div>

            {/* Menu */}
            <div className={styles.leftMenu} style={{width: '188px'}}>
                <ul className={styles.leftOptionUl} style={{backgroundColor: '#04070D'}}>
                    {/* My Assets */}
                    <li
                        className={
                            currentPath === '/'
                                ? `${styles.leftOption} ${styles.activeGrey}`
                                : styles.leftOption
                        }
                        onClick={handleMyAssetsClick}
                    >
                        <img
                            style={{marginRight: '12px'}}
                            src={transImg}
                            alt="My Assets icon"
                        />
                        My Assets
                    </li>

                    {/* Transactions */}
                    <li
                        className={
                            currentPath === '/transaction'
                                ? `${styles.leftOption} ${styles.activeGrey}`
                                : styles.leftOption
                        }
                        onClick={() => navigate('/transaction')}
                    >
                        <img
                            style={{marginRight: '12px', marginLeft: "-1px"}}
                            src={assetsImg}
                            alt="Transactions icon"
                        />
                        Transactions
                    </li>

                    <li
                        className={
                            currentPath === '/c2c'
                                ? `${styles.leftOption} ${styles.activeGrey}`
                                : styles.leftOption
                        }
                        onClick={() => navigate('/c2c')}>
                        <img
                            style={{marginRight: '9px', marginLeft: "-1px"}}
                            src={c2cImg}
                            alt="C2C icon"
                        />
                        C2C Network
                    </li>


                    {/* Invite Friends */}
                    <li className={styles.leftOption}>
                        <img
                            style={{marginRight: '9px', marginLeft: "-1px"}}
                            src={inviteImg}
                            alt="Invite icon"
                        />
                        Invite Friends
                    </li>
                </ul>
            </div>
            <div className={styles.leftMenu} style={{width: '188px'}}>
                <div
                        className={
                            currentPath === '/account'
                                ? `${styles.accountContainer} ${styles.activeGrey}`
                                : styles.accountContainer
                        }
                        onClick={() => navigate('/account')}>
                        <img
                            style={{marginRight: '9px', marginLeft: "-1px"}}
                            src={accountImg}
                            alt="account icon"
                        />
                        Account
                    </div>
            </div>
        </div>
    );
};

export default LeftPanel;