// components/Table/SmartContractTable.js
import React, { useEffect, useState } from 'react';
import {digitFormat} from "../utils";

const UserInfo = () => {
  const [totalUser, setTotalUser] = useState([]);
  const [activeUser, setActiveUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/total-users');

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setTotalUser(result);
        const response2 = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/active-users');
        if (!response2.ok) {
          throw new Error('Network response was not ok');
        }
        const result2 = await response2.json();
        setActiveUser(result2);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  // console.log(activeUser);
  return (
    <div className="table-responsive noto">
      <table className="table table-striped table-bordered mt-3">
        <thead className="thead-dark">
          <tr>
            <th>total users</th>
            <th>active users</th>
          </tr>
        </thead>
        <tbody>
            {/* <tr key={index}> */}
              <td>{digitFormat(totalUser.total_users)}</td>
              <td>{digitFormat(activeUser.active_users)}</td>
            {/* </tr> */}

        </tbody>
      </table>
    </div>
  );
};

export default UserInfo;
