import React, {useEffect, useState} from 'react';
import styles from './TransactionDetail.module.css';

import usdiIcon from '../static_files/usdi.svg';
import USDIcon from '../static_files/usd.svg';
import inProgressIcon from '../static_files/InProgress.png';
import {fetchC2CNetworkConfig} from '../utils'

function TransactionDetailModal({
                                    fromAmount,
                                    fromCurrency,
                                    toCurrency,
                                    transactionType,
                                    network,
                                    rate,
                                    eta,
                                    notes,
                                    onConfirm,
                                    onClose,
                                    localTime
                                }) {
    // local time is used to display to the user , and utc time is used to send to backend
    const [networkFeeAmount, setNetworkFeeAmount] = useState(0)
    const [toAmount, setToAmount] = useState(0);
    // network fee percentage comes from backend

    const [networkFeePercent, setNetworkFeePercent] = useState(0);
    useEffect(() => {
        async function loadFee() {
            const feePercent = await fetchC2CNetworkConfig();
            setNetworkFeePercent(feePercent.network_fee_percentage );
            const toAmountTemp = Number((Math.floor(fromAmount * (100 - feePercent.network_fee_percentage)) / 100
            ).toFixed(2))
            setToAmount(toAmountTemp)
            setNetworkFeeAmount(
            Math.floor((fromAmount - toAmountTemp) * 100) / 100
        );
        }
        loadFee();

    }, []);

    return (
        // 1) 最外層先放一個 overlay 的容器
        <div className={styles.modalOverlay}>
            {/* 2) 再放原本的 Modal 內容 */}
            <div className={styles.transactionDetailModal}>
                {/* Modal header */}
                <div className={styles.modalHeader}>
                    <h2>Transaction detail</h2>
                    <div
                        className={styles.closeBtn}
                        onClick={onClose}
                        aria-label="Close"
                    >
                        ×
                    </div>
                </div>

                <div className={styles.lineWrapper}>
                    <div className={styles.dividerLineFull}></div>
                </div>

                {/* Body content */}
                <div className={styles.modalBody}>
                    <div className={styles.statusSection}>
                        <div className={styles.statusImageContainer}>
                            <img src={inProgressIcon} alt="Status complete" className={styles.statusIcon}/>
                        </div>
                        <div className={styles.statusText}>In progress</div>
                    </div>

                    <div className={styles.transactionAmounts}>
                        <div className={styles.transactionFrom}>
                            <span className={styles.label}>From</span>
                            <div className={styles.amountRow}>
                                <img src={usdiIcon} alt="From currency"/>
                                <span className={styles.amount}>
                  -{fromAmount} {fromCurrency}
                </span>
                            </div>
                        </div>

                        <div className={styles.transactionTo}>
                            <span className={styles.label}>To</span>
                            <div className={styles.amountRow}>
                                <img src={USDIcon} alt="To currency"/>
                                <span className={styles.greenAmount}>
                                  +{Number(toAmount.toFixed(2))} {toCurrency}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.transactionDetails}>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Transaction type</span>
                            <span className={styles.detailValue}>{transactionType}</span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Time</span>
                            <span className={styles.detailValue}>{localTime}</span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Network</span>
                            <span className={styles.detailValue}>{network}</span>
                        </div>
                        <div className={styles.detailRow}>
              <span className={styles.detailLabel}>
                Network fee ({networkFeePercent}%)
              </span>
                            <span className={styles.detailValue}>{networkFeeAmount}</span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Rate</span>
                            <span className={styles.detailValue}>{rate}</span>
                        </div>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>ETA</span>
                            <span className={styles.detailValue}>{eta}</span>
                        </div>
                    </div>

                    <div className={styles.lineWrapper}>
                        <div className={styles.dividerLineFull}></div>
                    </div>

                    <div className={styles.transactionNotes}>
                        <p>{notes}</p>
                    </div>
                </div>

                {/* Modal footer */}
                <div className={styles.modalFooter}>
                    <button type="button" onClick={onConfirm} className={styles.confirmBtn}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TransactionDetailModal;
